import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapLibreGlMapComponent } from './map-libre-gl-map/map-libre-gl-map.component';
import { B3gisLazyLoadComponent } from './b3gis-lazy-load/b3gis-lazy-load.component';
import { b3dashboardLazyLoadComponent } from './b3dashboard-lazy-load/b3dashboard-lazy-load.component';
import { ExampleWrapperComponent } from './example-wrapper/example-wrapper.component';
import { MainMenuComponent } from './main-menu/main-menu.component';

@NgModule({
  declarations: [AppComponent, MapLibreGlMapComponent, B3gisLazyLoadComponent, b3dashboardLazyLoadComponent, ExampleWrapperComponent, MainMenuComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
