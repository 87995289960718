export function regionMapExample() {
  const element = document.querySelector('#example-wrapper__right-panel-content');
  const b3Gis = b3GisLib.init(element, {
    controls: {
      layerTree: true,
      goToGis: true,
      identification: true,
      timeline: true,
      toolbarButtons: ['layerTree', 'timeline', 'select', 'identification', 'measure-distance', 'measure-area', 'snapshot-for-report']
    },
    backendUser: 'ЮрийБуров',
    backendPassword: 'a3;n49na4t4',
    layerTreeVisible: false,
    mapName: 'demo-region-map',
    backendUrl: 'https://gis.big3.ru/',
    onClick: (eventData) => {
      window.console.log(eventData);
    }
  });

  b3Gis.setGisState({
    editingLayerId: 'region',
    layers: {
      editable: true,
      region: {
        fit: { animate: false },
        geoJson: {
          type: 'Feature',
          properties: {
            id: 1
          },
          geometry: {
            coordinates: [
              [
                [37.37406011866506, 55.735103988659375],
                [37.394250061131686, 55.69380077121241],
                [37.44313097447093, 55.64166053438183],
                [37.50263817331927, 55.59605587043467],
                [37.587648457388354, 55.575636605147196],
                [37.675846627110275, 55.574435141080045],
                [37.7491679971196, 55.60025851830568],
                [37.84586719524842, 55.65245386990679],
                [37.83311565263807, 55.714757136398475],
                [37.855430852205956, 55.806234510433654],
                [37.80017416756104, 55.86829276264555],
                [37.66840822725396, 55.90285916488088],
                [37.527078629989404, 55.90285916488088],
                [37.41018948939393, 55.872466203381634],
                [37.362371204605324, 55.80563731564476],
                [37.37406011866506, 55.735103988659375]
              ]
            ],
            type: 'Polygon'
          }
        }
      }
    }
  });
}
