import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { init } from '@mm/b3-gis-types/dist/b3-gis';
import { loadScriptByUrl, loadStyle } from '../load-util';

type B3Gis = {
  init: typeof init;
};

const JS_FILE_NAME = 'b3-gis.umd.js';
const CSS_FILE_NAME = 'style.css';
const DEFAULT_LIB_URL = 'https://gis.big3.ru/lib/';

@Component({
  selector: 'app-b3gis-lazy-load',
  templateUrl: './b3gis-lazy-load.component.html',
  styleUrls: ['./b3gis-lazy-load.component.scss']
})
export class B3gisLazyLoadComponent {
  count = 0;
  map: null;
  @ViewChild('mapElement') mapElement: ElementRef;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngAfterViewInit() {
    this.mapElement.nativeElement.classList.add('dx-viewport');
    const libUrl = this.activatedRoute.snapshot.queryParamMap.get('lib_url') || DEFAULT_LIB_URL;

    if (!(window as unknown as any).b3Gis) {
      loadStyle(libUrl, CSS_FILE_NAME);
      loadScriptByUrl(`${libUrl}${JS_FILE_NAME}`, (scriptref, id) => {
        const windowAsAny = window as unknown as any;
        const b3GisLib = (window as unknown as any).b3Gis as B3Gis;

        windowAsAny.createGis = (options: any) => {
          return b3GisLib.init(this.mapElement.nativeElement, options);
        };

        window.console.log('B3Gis script loaded');

        /*
        const b3Gis = b3GisLib.init(this.mapElement.nativeElement, {
          controls: {
            layerTree: true,
            goToGis: true,
            identification: true,
            timeline: true,
            toolbarButtons: [
              'layerTree',
              'timeline',
              'select',
              'identification',
              'measure-distance',
              'measure-area',
              'snapshot-for-report'
            ]
          },
          layerTreeVisible: false,
          mapName: 'burov-operative-control',
          backendUrl: 'https://gis.big3.ru/',
          onClick: (eventData: any) => {
            window.console.log(eventData);
          }
        });
        //*/
      });
    }
  }
}
