let count = 0;

export function loadScriptByUrl(url: string, afterload: (dynamicScript: HTMLScriptElement, elementId: string) => void) {
  let dynamicScript = document.createElement('script');
  dynamicScript.type = 'text/javascript';
  dynamicScript.async = true;
  dynamicScript.src = url;
  dynamicScript.id = 'dynamic_' + count;
  document.head.appendChild(dynamicScript);
  count++;

  dynamicScript.addEventListener('load', () => {
    afterload(dynamicScript, dynamicScript.id);
  });
}

export function loadStyle(libUrl: string, cssFileName: string) {
  const style = document.createElement('link');
  style.id = 'client-theme';
  style.rel = 'stylesheet';
  style.type = 'text/css';
  style.href = `${libUrl}${cssFileName}`;
  document.head.appendChild(style);
}
