<div class="app">
  <!--
  <app-b3gis-lazy-load />
  -->
  <!--
  <app-map-libre-gl-map/>
  -->
  <!--
  <app-b3dashboard-lazy-load />
  -->
  <router-outlet></router-outlet>
</div>
