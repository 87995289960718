import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { init } from '@mm/b3-dashboard-types/dist/b3-dashboard';

type B3Dashboard = {
  init: typeof init;
};

const JS_FILE_NAME = 'b3-dashboard.umd.js';
const CSS_FILE_NAME = 'style.css';
const DEFAULT_LIB_URL = 'https://gis.big3.ru/lib-dashboard/';

@Component({
  selector: 'app-b3dashboard-lazy-load',
  templateUrl: './b3dashboard-lazy-load.component.html',
  styleUrls: ['./b3dashboard-lazy-load.component.scss']
})
export class b3dashboardLazyLoadComponent {
  count = 0;
  map: null;
  @ViewChild('dashboardElement') dashboardElement: ElementRef;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngAfterViewInit() {
    const libUrl = this.activatedRoute.snapshot.queryParamMap.get('lib_url') || DEFAULT_LIB_URL;

    this.loadStyle(libUrl);

    this.loadScriptByUrl(`${libUrl}${JS_FILE_NAME}`, (scriptref, id) => {
      const windowAsAny = window as unknown as any;
      const b3DashboardLib = windowAsAny.b3Dashboard as B3Dashboard;

      windowAsAny.createDashboard = (options: any) => {
        return b3DashboardLib.init(this.dashboardElement.nativeElement, options);
      };

      window.console.log('B3Dashboard script loaded');

      /*
      const b3Dashboard = b3DashboardLib.init(this.dashboardElement.nativeElement, {
        backendUrl: 'https://gis.big3.ru/',
        dashboardName: 'test-events'
        //jwt: 'jwtjwtjwt'
      });
      //*/
    });
  }

  loadScriptByUrl(url: string, afterload: (dynamicScript: HTMLScriptElement, elementId: string) => void) {
    let dynamicScript = document.createElement('script');
    dynamicScript.type = 'text/javascript';
    dynamicScript.async = true;
    dynamicScript.src = url;
    dynamicScript.id = 'dynamic_' + this.count;
    document.head.appendChild(dynamicScript);
    this.count++;

    dynamicScript.addEventListener('load', () => {
      afterload(dynamicScript, dynamicScript.id);
    });
  }

  loadStyle(libUrl: string) {
    const style = document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = `${libUrl}${CSS_FILE_NAME}`;
    document.head.appendChild(style);
  }
}
