<div class="example-wrapper">
  <div class="example-wrapper__left-panel">
    <div class="example-wrapper__top-buttons">
      <button
        type="button"
        (click)="applyCode()"
      >
        Применить
      </button>
    </div>
    <div
      #codemirrorElement
      class="example-wrapper__codemirror"
    ></div>
  </div>
  <div class="example-wrapper__right-panel">
    <div
      class="example-wrapper__right-panel-content"
      id="example-wrapper__right-panel-content"
    ></div>
  </div>
</div>
