import { Component } from '@angular/core';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  menuItems = [
    {
      name: 'ГИС - пустая страница для тестирования через консоль',
      href: '/gis'
    },
    {
      name: 'Информационная панель - пустая страница для тестирования через консоль',
      href: '/dashboard'
    },
    {
      name: 'Карта региона - демо',
      href: '/region-map-example'
    },
    {
      name: 'Карта района - демо',
      href: '/district-map-example'
    }
  ];
}
